import './index.scss'

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Url from 'url-parse'

import { pampersDark } from '../../assets'
import { Spinner } from '../../components'
import i18n from '../../i18n'
import getProgramCode from '../change-password/get-program-code'

export default ({
  location: { search },
  match: {
    params: { locale = 'en-us' }
  }
}) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const verifyEmail = async (verificationCode) => {
    try {
      locale = locale === 'ja-JP' ? 'ja-jp' : locale
      const programcode = getProgramCode(locale)

      await axios({
        data: {
          code: verificationCode
        },
        headers: {
          'content-type': 'application/json',
          programcode
        },
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}/verifyEmail`
      })

      setCode(verificationCode)
    } catch (_) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const {
      query: { code: verificationCode }
    } = new Url(search, true)

    const [country, language = ''] = locale.split('-')

    i18n.locale = `${country}_${language.toUpperCase()}`

    if (verificationCode) {
      verifyEmail(verificationCode)
    }
  }, [search, locale])

  return (
    <div className="registration">
      <img src={pampersDark} alt="Pampers Rewards" />
      {loading && <Spinner />}
      {!loading && error && (
        <>
          <h1>{i18n.t('registration_failed_title')}</h1>
          <p>{i18n.t('registration_failed_message')}</p>
        </>
      )}
      {!loading && !error && (
        <>
          <h1>{i18n.t('registration_success')}</h1>
          <h2>{i18n.t('registration_title')}</h2>
          <p>{i18n.t('registration_message')}</p>
          <button
            onClick={() => {
              let deeplink = process.env.REACT_APP_REGISTRATION_DEEP_LINK

              deeplink =
                locale === 'en-ca'
                  ? deeplink.replace('pampersclubusnotif', 'pampersclubcanotif')
                  : deeplink
              window.location = `${deeplink}&token=${code}`
            }}
          >
            {i18n.t('registration_action')}
          </button>
        </>
      )}
    </div>
  )
}
