import { camelCase } from 'lodash'
import React, { Component, createRef } from 'react'
import Url from 'url-parse'

import { faviconDodot } from '../../assets'
import { Header, Spinner } from '../../components'
import i18n from '../../i18n'
import getProgramCode from './get-program-code'

import PasswordVulnerability from '../vps'
import './index.scss'
import apiService from '../../service/api.service'

export default class ChangePassword extends Component {
  // initial state
  state = {
    code: '',
    password: '',
    done: false,
    errors: [],
    loading: false,
    showPopup: false,
    checkVps: false,
    programcode: ''
  }

  constructor(props) {
    super(props)

    this.passwordSubmitBtn = createRef()
    this.passwordInput = createRef()

    this.triggerPasswordVulnerablePopup = this.triggerPasswordVulnerablePopup.bind(
      this
    )
  }

  componentWillMount = () => {
    // set locale
    console.log('props-----------', this.props)
    i18n.setLocale(this.props)
  }

  componentDidMount = () => {
    // get querystring from location
    const {
      location: { search }
    } = this.props

    this.passwordInput.focus()
    console.log(this.props.match.params.locale, 'props')
    const locale = String(this.props.match.params.locale).toLowerCase()

    const programcode = getProgramCode(locale)

    // parse and get code
    const {
      query: { code }
    } = new Url(search, true)

    // locale is for DoDot
    const isDoDot = process.env.REACT_APP_TYPE === 'dodot'
    const isVpsEnabled = {
      ES: process.env.REACT_APP_ES_VPS_ENABLED,
      US: process.env.REACT_APP_US_VPS_ENABLED,
      CA: process.env.REACT_APP_CA_VPS_ENABLED,
      DE: process.env.REACT_APP_DE_VPS_ENABLED,
      JP: process.env.REACT_APP_JP_VPS_ENABLED
    }

    // change page title
    if (isDoDot) {
      document.title = 'Dodot VIP'

      const favicon = document.getElementById('favicon')

      favicon.href = faviconDodot
    }

    this.setState({
      code,
      isDoDot,
      checkVps: String(isVpsEnabled[programcode]) === 'true',
      locale,
      programcode
    })
  }

  /**
   * Password change handler
   * @param {Object} event Keyboard event
   */
  passwordOnChange = ({ target: { value: password } }) => {
    this.setState({
      password
    })
  }

  /**
   * Hide error on click
   * @param {string} type Error type
   */
  hideError = type => {
    const { errors } = this.state

    // find index of error
    const index = errors.findIndex(error => error.type === type)

    // splice it out of the array
    errors.splice(index, 1)

    this.setState({
      errors
    })
  }

  triggerPasswordVulnerablePopup({ canShow, changeMyPassword }) {
    const state = {}

    if (changeMyPassword !== undefined) {
      if (changeMyPassword) {
        // setTimeout(
        //   (element) => {
        //     element.focus()
        //   },
        //   500,
        //   this.passwordInput.current
        // )
        state.checkVps = true
      } else {
        this.passwordSubmitBtn.current.click()
        state.checkVps = false
      }
    } else {
      state.checkVps = false
    }

    state.showPopup = !canShow

    this.setState(state)
  }

  /**
   * Form submission handler
   * @param {Object} event Form event
   */
  handleSubmit = async event => {
    event.preventDefault()

    const { checkVps, code, password, programcode, locale } = this.state
    // validate that both fields exist
    if (code && password) {
      try {
        if (checkVps) {
          const {
            isVulnerablePassword = false
          } = await apiService.checkPasswordVulnerability({
            locale,
            password,
            programcode
          })

          if (isVulnerablePassword) {
            this.triggerPasswordVulnerablePopup({
              canShow: false
            })

            return false
          }
        }

        // show spinner
        this.setState({
          loading: true
        })

        await apiService.resetPassword({
          code,
          password,
          programcode
        })

        this.setState({
          done: true
        })
      } catch (err) {
        const {
          errors = [
            {
              type: 'unknown_error'
            }
          ]
        } = err

        this.setState({
          errors
        })
      } finally {
        // hide spinner
        this.setState({
          loading: false
        })
      }
    }
  }

  render() {
    const {
      code,
      password,
      done,
      errors,
      loading,
      isDoDot,
      showPopup
    } = this.state

    if (done) {
      return (
        <div className={isDoDot ? 'wrapperDodot' : 'wrapper'}>
          <Header dodot={isDoDot} />
          <div
            className={isDoDot ? 'change-password-dodot' : 'change-password'}
          >
            <header>
              <h1>{i18n.t('changePassword_passwordChanged_title')}</h1>
              <p>
                {i18n.t(
                  isDoDot
                    ? 'changePassword_passwordChanged_message_dodot'
                    : 'changePassword_passwordChanged_message'
                )}
              </p>
            </header>
          </div>
        </div>
      )
    }

    return (
      <div className={isDoDot ? 'wrapperDodot' : 'wrapper'}>
        <Header dodot={isDoDot} />
        <div className={isDoDot ? 'change-password-dodot' : 'change-password'}>
          <header>
            <h1>{i18n.t('changePassword_title')}</h1>
            <p>
              {i18n.t(
                isDoDot
                  ? 'changePassword_message_dodot'
                  : 'changePassword_message'
              )}
            </p>
          </header>
          <main>
            {errors.map(({ type }) => (
              <div
                key={type}
                className="error"
                onClick={() => this.hideError(type)}
              >
                {i18n.t(`changePassword_error_${camelCase(type)}`)}
              </div>
            ))}
            <form onSubmit={this.handleSubmit}>
              <label>
                {i18n.t('changePassword_code')}
                <input
                  type="text"
                  className={code ? 'valid' : 'invalid'}
                  placeholder={i18n.t('changePassword_code')}
                  readOnly
                  required
                  value={code}
                />
              </label>
              <label>
                {i18n.t('changePassword_newPassword')}
                <span>{i18n.t('changePassword_requirements')}</span>
                <input
                  type="password"
                  onChange={this.passwordOnChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                  placeholder={i18n.t('changePassword_password')}
                  required
                  value={password}
                  ref={input => {
                    this.passwordInput = input
                  }}
                />
              </label>
              <div>
                <div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <button ref={this.passwordSubmitBtn}>
                      {i18n.t('changePassword_title')}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </main>
        </div>
        <PasswordVulnerability
          show={showPopup}
          setShow={this.triggerPasswordVulnerablePopup}
        />
      </div>
    )
  }
}
