import React, { Component } from 'react'

import { pampers, dodotVip } from '../../assets'

export default class Header extends Component {
  render() {
    return (
      <header>
        <img
          src={this.props.dodot ? dodotVip : pampers}
          alt={this.props.dodot ? 'Dodot VIP' : 'Pampers Rewards'}
        />
      </header>
    )
  }
}
