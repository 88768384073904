import axios from 'axios'

class ApiService {
  constructor() {
    this.apiUri = process.env.REACT_APP_API_URI
  }

  /**
   * reset password
   * @param {object} param request body
   * @returns {Promise} response
   */
  async resetPassword({ code, password, programcode }) {
    return this.request({
      url: '/resetPassword',
      method: 'POST',
      headers: { programcode },
      data: { code, password }
    })
  }

  /**
   * check password vulnerability
   * @param {object} param request body
   * @returns {Promise} response
   */
  async checkPasswordVulnerability({ locale, password, programcode }) {
    return this.request({
      url: '/passwordValidator',
      method: 'POST',
      headers: { programcode, locale },
      data: { password }
    })
  }

  /**
   * request
   * @param {object} param request options
   * @returns {Promise} response
   */
  async request({ method = 'GET', data = {}, headers = {}, url = '' }) {
    try {
      const options = {
        url: this.apiUri + url,
        method,
        headers
      }

      if (method === 'GET') {
        options.params = data
      } else {
        options.data = data
      }

      const { data: response } = await axios(options)

      return response
    } catch (err) {
      console.log('err', err)
      if (err && err.response && err.response.data) throw err.response.data

      throw err
    }
  }
}

export default new ApiService()
