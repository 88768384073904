import './index.scss'

import * as serviceWorker from './serviceWorker'

import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import {
  ChangePassword,
  MemberBenefits,
  PaypalLinking,
  Registration
} from './scenes'

import React from 'react'
import ReactDOM from 'react-dom'
import Url from 'url-parse'

const RedirectChangePassword = (props) => {
  // get querystring from location
  const {
    location: { search }
  } = props

  // parse and get code
  const {
    query: { code }
  } = new Url(search, true)

  // redirect to en-us if locale no passed
  return <Redirect to={`/en-us/change-password?code=${code}`} />
}

const RedirectRegistration = (props) => {
  // get querystring from location
  const {
    location: { search }
  } = props

  // parse and get code
  const {
    query: { code }
  } = new Url(search, true)

  // redirect to en-us if locale no passed
  return <Redirect to={`/en-us/registration?code=${code}`} />
}

ReactDOM.render(
  <BrowserRouter>
    <>
      <Route path="/:locale/change-password" component={ChangePassword} />
      <Route path="/:locale/member-benefits" component={MemberBenefits} />
      <Route path="/:locale/registration" component={Registration} />
      <Route path="/change-password" component={RedirectChangePassword} exact />
      <Route path="/paypal-linking" component={PaypalLinking} />
      <Route path="/registration" component={RedirectRegistration} exact />
    </>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister()
