import './index.scss'

import React from 'react'

export const Spinner = ({ fullscreen }) => {
  if (fullscreen) {
    return (
      <div className="loading">
        <div className="spinner" />
      </div>
    )
  }

  return <div className="spinner" />
}
