import React, { useCallback } from 'react'

import { Modal } from 'react-bootstrap'

import i18n from '../../i18n'

import errorIcon from '../../assets/error.png'

const PasswordVulnerability = ({ show = false, setShow }) => {
  const hideShowModal = useCallback(
    (changeMyPassword = true) => {
      setShow({ canShow: show, changeMyPassword })
    },
    [show, setShow]
  )

  return (
    <>
      <Modal
        show={show}
        onHide={hideShowModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        keyboard="false"
        backdrop="static"
      >
        <Modal.Header>
          <div className="alert-icon-container">
            <img className="alert-icon" src={errorIcon} alt="alert" />
          </div>
          <div>
            <Modal.Title id="example-custom-modal-styling-title">
              {i18n.t('password_vulnerable_user_dialog_title')}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>{i18n.t('password_vulnerable_user_dialog_description')}</p>
          {/* <p>{i18n.t('password_vulnerable_user_dialog_description_two')}</p> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="iqwMs">
            <button
              className="sc-jRQAMF dVWtnQ btn medium primary-coral reset-password-submit-button"
              onClick={() => hideShowModal(true)}
            >
              {i18n.t('password_vulnerable_user_dialog_primary_cta_text')}
            </button>
          </div>
          {/* <div>
            <p
              className="dVWtnQ btn medium text-teal"
              onClick={() => hideShowModal(false)}
            >
              {i18n.t('password_vulnerable_user_dialog_secondary_cta_text')}
            </p>
          </div> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PasswordVulnerability
