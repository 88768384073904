/**
 * get programcode by locale
 * @param {String} locale locale
 * @returns {String} programcode
 */
const getProgramCode = locale => {
  console.log('locale', locale)

  const marketLocales = process.env.REACT_APP_MARKET_LOCALES.split(';').map(
    marketLocale => {
      const [market, locales = ''] = marketLocale.split(',')

      return {
        market,
        supportedLocales: locales.split('~')
      }
    }
  )

  console.log('marketLocales', marketLocales)

  const programcode = marketLocales.find(market =>
    market.supportedLocales.includes(locale)
  ).market

  console.log('programcode', programcode)

  return programcode
}

export default getProgramCode
